import type { SVGAttributes } from 'react';

const ArrowUpIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      d="M10.102 16.724V4.4l5.385 5.384c.43.43 1.137.43 1.567 0 .43-.43.43-1.125 0-1.555L9.782.956a1.099 1.099 0 0 0-1.555 0L.944 8.216c-.43.43-.43 1.126 0 1.556.43.43 1.125.43 1.556 0L7.896 4.4v12.325c0 .607.496 1.104 1.103 1.104s1.103-.497 1.103-1.104Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowUpIcon;
