import {
  Fragment,
  ReactNode,
  useCallback,
  useEffect,
  KeyboardEvent,
  MouseEvent,
} from 'react';
import {
  useFlexLayout,
  useTable,
  useSortBy,
  Column,
  useFilters,
  type TableState,
  type Row,
  type IdType,
  type RowPropGetter,
  useExpanded,
  SortingRule,
  Cell,
} from 'react-table';
import {
  TableWrapper,
  TableHead,
  TableBody,
  Td,
  Tr,
  TableContainer,
  TableFooter,
} from './Table.styles';
import { keyHandler } from '../../utils/accessibilityUtils';
import TableHeader from './TableHeader/TableHeader';
import { NextRouter, useRouter } from 'next/router';
import copyToClipboard from '../../utils/copyToClipboard';
import ClipIcon from '../icons/ClipIcon';
import { useToast } from '../Toasts/useToast';

export type TableVariant = 'primary' | 'secondary';

type ColumnExtras<T extends object> = {
  copyValueOnClick?: boolean;
  onCellClick?: (
    cell: Cell<T>,
    router: NextRouter,
    event: MouseEvent<HTMLTableCellElement>,
  ) => void;
};

type ColumnWithExtras<T extends object> = Column<T> & ColumnExtras<T>;

export type ColumnType<T extends object> = Array<ColumnWithExtras<T>>;

export type TableProps<T extends object> = {
  columns: ColumnType<T>;
  data: Array<T>;
  rowHeight?: number;
  autoMinRowHeight?: number;
  autoHeight?: boolean;
  hideHeader?: boolean;
  hideBodyBorder?: boolean;
  filter?: string;
  initialState?: Partial<TableState<T>>;
  sort?: SortingRule<T>[];
  onRowHoverEnter?: (id: string) => void;
  onRowSelect?: (rowNumber: number, row?: Row<T>) => void;
  onRowHoverLeave?: () => void;
  onFilter?: (fn: (columnId: string, updater: string) => void) => void;
  renderRowSubComponent?: (row: Row<T>) => ReactNode;
  onSortChange?: (sortBy: SortingRule<T>[]) => void;
  getRowProps?: (row: Row<T>) => RowPropGetter<T>;
  variant?: TableVariant;
  headerRowHeight?: number;
  tablePadding?: number;
  className?: string;
};

function Table<T extends object>({
  columns,
  data,
  sort,
  initialState,
  onRowHoverEnter,
  onRowHoverLeave,
  onRowSelect,
  rowHeight,
  autoMinRowHeight,
  onSortChange,
  autoHeight,
  hideHeader,
  hideBodyBorder,
  filter,
  onFilter,
  renderRowSubComponent,
  getRowProps,
  variant,
  className,
  headerRowHeight,
  tablePadding,
}: TableProps<T>) {
  const router = useRouter();
  const { toast } = useToast();
  const isSecondaryVariant = variant === 'secondary';
  const _headerRowHeight =
    isSecondaryVariant && !headerRowHeight ? 57 : headerRowHeight;
  const _tablePadding = isSecondaryVariant && !tablePadding ? 32 : tablePadding;

  const sortAlphabeticalFn = useCallback(
    (rowA: Row<T>, rowB: Row<T>, id: IdType<string>): number => {
      if (rowA?.values[id]?.toUpperCase() > rowB?.values[id]?.toUpperCase())
        return 1;
      if (rowB?.values[id]?.toUpperCase() > rowA?.values[id]?.toUpperCase())
        return -1;
      return 0;
    },
    [],
  );

  const sortNumericalFn = useCallback(
    (rowA: Row<T>, rowB: Row<T>, id: IdType<string>): number => {
      if (rowA.values[id] > rowB.values[id]) return 1;
      if (rowB.values[id] > rowA.values[id]) return -1;
      return 0;
    },
    [],
  );

  const sortDateTimeFn = useCallback(
    (rowA: Row<T>, rowB: Row<T>, id: IdType<string>): number => {
      const a: Date = rowA?.values?.[id];
      const b: Date = rowB?.values?.[id];

      return (
        Number(a === null) - Number(b === null) ||
        new Date(a)?.getTime() - new Date(b)?.getTime()
      );
    },
    [],
  );

  const handleCellClick = useCallback(
    (cell: Cell<T>) => (event: MouseEvent<HTMLTableCellElement>) => {
      const columnWithExtras = cell.column as ColumnWithExtras<T>;
      if (columnWithExtras.copyValueOnClick) {
        event.stopPropagation();
        copyToClipboard(cell.value);

        const friendlyHeader =
          typeof columnWithExtras.Header === 'string'
            ? columnWithExtras.Header
            : 'value';
        toast({
          description: `Copied ${friendlyHeader} to clipboard!`,
        });
      }

      if (columnWithExtras.onCellClick) {
        event.stopPropagation();
        columnWithExtras.onCellClick(cell, router, event);
      }
    },
    [router, toast],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setFilter,
    setSortBy,
    visibleColumns,
    footerGroups,
  } = useTable<T>(
    {
      columns,
      data,
      manualSortBy: Boolean(onSortChange),
      disableSortRemove: true,
      sortTypes: {
        alphabetical: sortAlphabeticalFn,
        numerical: sortNumericalFn,
        datetime: sortDateTimeFn,
      },
      initialState: {
        ...initialState,
        sortBy: sort || initialState?.sortBy || [],
      },
    },
    useFlexLayout,
    useFilters,
    useSortBy,
    useExpanded,
  );

  useEffect(() => {
    if (onSortChange) {
      onSortChange(state.sortBy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sortBy]);

  useEffect(() => {
    // if sort prop is passed, and it has a length,
    // use that instead of loading default value.
    if (sort && sort.length) {
      return;
    }
    // use the initialState prop if provided
    if (initialState?.sortBy && initialState.sortBy.length) {
      setSortBy(initialState.sortBy);
      return;
      // or automatically load the first column as the sort
    }
    const firstRow = columns.at(0);
    const id = firstRow?.id || firstRow?.accessor;
    if (typeof id === 'string') {
      setSortBy([
        {
          id,
          desc: false,
        },
      ]);
      return;
    }

    setSortBy([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState?.sortBy]);

  useEffect(() => {
    onFilter && onFilter(setFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFilter, filter]);

  const showFooter = columns.some((column) => column.Footer);

  return (
    <TableContainer>
      <TableWrapper
        {...getTableProps()}
        className={className}
        $isSecondaryTheme={isSecondaryVariant}
        $tablePadding={_tablePadding}
      >
        {!hideHeader ? (
          <TableHead
            $isSecondaryTheme={isSecondaryVariant}
            $tablePadding={_tablePadding}
          >
            {headerGroups.map((headerGroup) => (
              <Tr
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.headers[0].id}
              >
                {headerGroup.headers.map((column) => (
                  <TableHeader
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    {...(column.canSort
                      ? {
                          tabIndex: 0,
                          onKeyDown: (e: KeyboardEvent) =>
                            keyHandler({
                              keyEvent: e,
                              eventHandler: () => column.toggleSortBy(),
                            }),
                        }
                      : {})}
                    key={`th-${column.id}`}
                    variant={variant}
                    isSorted={column.isSorted}
                    isSortedDesc={column.isSortedDesc}
                    rowHeight={_headerRowHeight}
                  >
                    {column.render('Header')}
                  </TableHeader>
                ))}
              </Tr>
            ))}
          </TableHead>
        ) : (
          <></>
        )}
        <TableBody
          {...getTableBodyProps()}
          $hideBodyBorder={hideBodyBorder}
          $tablePadding={_tablePadding}
        >
          {rows.map((row, i) => {
            prepareRow(row);
            const rowProps = getRowProps
              ? { ...row.getRowProps(getRowProps(row)) }
              : { ...row.getRowProps() };

            return (
              <Fragment key={`rt-tb-trs${i}`}>
                <Tr
                  {...rowProps}
                  key={row.id}
                  onMouseEnter={() =>
                    onRowHoverEnter && onRowHoverEnter(row.id)
                  }
                  onMouseLeave={onRowHoverLeave}
                  onClick={() =>
                    onRowSelect && onRowSelect(Number(row.id), row)
                  }
                  {...(onRowSelect
                    ? {
                        tabIndex: 0,
                        onKeyDown: (e: KeyboardEvent) => {
                          keyHandler({
                            keyEvent: e,
                            eventHandler: () =>
                              onRowSelect(Number(row.id), row),
                          });
                        },
                      }
                    : {})}
                  {...(onRowHoverEnter
                    ? { onFocus: () => onRowHoverEnter(row.id) }
                    : {})}
                >
                  {row.cells.map((cell) => {
                    const columnWithExtras = cell.column as ColumnWithExtras<T>;
                    const hasCopyValueOnClick =
                      !!columnWithExtras.copyValueOnClick;
                    const hasOnClick =
                      !!columnWithExtras.onCellClick || hasCopyValueOnClick;

                    return (
                      // this is added by the cellProps
                      // eslint-disable-next-line react/jsx-key
                      <Td
                        {...cell.getCellProps()}
                        $rowHeight={rowHeight}
                        $hasOnClick={hasOnClick}
                        $autoHeight={autoHeight}
                        $autoMinRowHeight={autoMinRowHeight}
                        onClick={hasOnClick ? handleCellClick(cell) : undefined}
                      >
                        {cell.render('Cell')}
                        {hasCopyValueOnClick && <ClipIcon />}
                      </Td>
                    );
                  })}
                </Tr>
                {!!renderRowSubComponent && row.isExpanded ? (
                  <tr key={`tr-${row.id}-sub`}>
                    <td
                      colSpan={visibleColumns.length}
                      key={`td-${row.id}-sub`}
                    >
                      {renderRowSubComponent(row)}
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })}
        </TableBody>
        {showFooter && (
          <TableFooter
            $tablePadding={_tablePadding}
            $hideBodyBorder={hideBodyBorder}
          >
            {footerGroups.map((group) => {
              const { key: footerGroupKey, ...footerGroupProps } =
                group.getFooterGroupProps();

              return (
                <Tr key={footerGroupKey} {...footerGroupProps}>
                  {group.headers.map((column) => {
                    const { key: footerKey, ...footerProps } =
                      column.getFooterProps();

                    return (
                      <Td key={footerKey} {...footerProps}>
                        {column.render('Footer')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </TableFooter>
        )}
      </TableWrapper>
    </TableContainer>
  );
}

export default Table;
