import styled, { css } from 'styled-components';
import { TableVariant } from '../Table';

const primary = css`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
`;

const secondary = css`
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
`;

const variantStyles = {
  primary,
  secondary,
};

export const Th = styled.th<{ $rowHeight?: number; $variant: TableVariant }>`
  margin: 0;
  padding: 0 15px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.05px;
  box-sizing: border-box;
  font-family: ${(props) => props.theme.fontFamily.main};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.gray300}`};
  height: ${({ $rowHeight }) => ($rowHeight ? `${$rowHeight}px` : '50px')};

  align-items: center;
  display: flex;
  ${({ $variant }) => variantStyles[$variant]}
`;

export const SortIconContainer = styled.span<{
  $flip?: boolean;
}>`
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) => (props.$flip ? 'scale(1, -1)' : 'none')};
  width: 12px;
  height: 12px;
`;
