import styled from 'styled-components';
import Typography from '../../../Typography';

export const Container = styled.div`
  text-transform: uppercase;
  text-align: center;
`;

export default function TableNoResultsMessage({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <Container className="Wreno_Table--NoResultsMessage">
      <Typography color="inherit" variant="ctaSmall">
        {children ?? 'No results found'}
      </Typography>
    </Container>
  );
}
