import styled from 'styled-components';

const TableContainer = styled.div`
  margin: 0 0 60px;
  display: flex;
  flex-direction: column;
  gap: 32px;

  &:has(.Wreno_Pagination--container) {
    margin-bottom: 24px;
  }
  .Wreno_Table--NoResultsMessage {
    margin-top: 50px;
  }
  .Wreno__Error--container {
    margin-top: 20px;
  }
`;

export default TableContainer;
