import { PropsWithChildren, ReactNode } from 'react';

import {
  TextCellContainer,
  ProfilePicture,
  PrefixContainer,
} from './TextCell.styles';

type Avatar = {
  type: 'avatar';
  name: string;
  url?: string;
};

type Icon = {
  type: 'icon';
  component: ReactNode;
};

export interface TextCellProps {
  prefix?: Icon | Avatar;
  fontWeight?: 'regular' | 'medium' | 'bold';
}
const Prefix = ({ prefix }: { prefix: Icon | Avatar }) => {
  if (prefix.type === 'icon') {
    return <PrefixContainer>{prefix.component}</PrefixContainer>;
  }

  return <ProfilePicture firstName={prefix.name} url={prefix.url} />;
};

function TextCell(props: PropsWithChildren<TextCellProps>) {
  const { children, prefix, fontWeight } = props;

  return (
    <TextCellContainer $fontWeight={fontWeight}>
      {prefix && <Prefix prefix={prefix} />}
      <div>{children}</div>
    </TextCellContainer>
  );
}

export default TextCell;
