import type { SVGAttributes } from 'react';

const ClipIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width={12}
    height={23}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 23"
    {...props}
  >
    <path
      d="M2 16.75V6.17c0-2.09 1.53-3.95 3.61-4.15A3.998 3.998 0 0 1 10 6v12.36c0 1.31-.94 2.5-2.24 2.63A2.5 2.5 0 0 1 5 18.5V8c0-.55.45-1 1-1s1 .45 1 1v8.75c0 .41.34.75.75.75s.75-.34.75-.75V8.14c0-1.31-.94-2.5-2.24-2.63A2.5 2.5 0 0 0 3.5 8v10.33c0 2.09 1.53 3.95 3.61 4.15 2.39.23 4.39-1.64 4.39-3.98V6.23C11.5 3.36 9.4.79 6.54.52A5.505 5.505 0 0 0 .5 6v10.75c0 .41.34.75.75.75s.75-.34.75-.75Z"
      fill="currentColor"
    />
  </svg>
);

export default ClipIcon;
