import Checkbox from '../../Checkbox';
import { CheckboxProps } from '../../Checkbox/Checkbox';
import { CheckboxCellContainer } from './CheckboxCell.styles';

export default function RadioCell(props: CheckboxProps) {
  return (
    <CheckboxCellContainer>
      <Checkbox {...props} />
    </CheckboxCellContainer>
  );
}
