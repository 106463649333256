import styled from 'styled-components';
import BaseCheckIcon from '../icons/CheckIcon';
import BaseLabel from '../Label';
import BaseError from '../Error';

interface CheckboxWrapperProps {
  $disabled?: boolean;
  $withLabel?: boolean;
}

export const CheckboxContainer = styled.div`
  display: inline-block;
`;

export const Input = styled.input`
  width: 18px;
  height: 18px;
  margin: 0;
  border: 2px solid ${(props) => props.theme.colors.black};
  background-color: ${(props) => props.theme.colors.white};
  appearance: none;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  &:disabled {
    border: 2px solid ${(props) => props.theme.colors.gray500};
  }
  &:checked {
    border: 2px solid ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.black};
  }
  &:indeterminate {
    border: 2px solid ${(props) => props.theme.colors.black};
    background-color: ${(props) => props.theme.colors.black};
  }
`;

export const CheckboxLine = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 2px;
  background-color: ${(props) => props.theme.colors.white};
`;

export const CheckIcon = styled(BaseCheckIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  color: ${({ theme }) => theme.colors.white};
`;

export const Label = styled(BaseLabel)`
  margin: 0;
  padding: 0 0 0 6px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  > div {
    margin: 0;
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  width: fit-content;
`;

export const Container = styled.div<CheckboxWrapperProps>`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  ${({ $disabled }) =>
    $disabled &&
    `
    opacity: 0.4;
  `}
  ${({ $withLabel }) =>
    $withLabel &&
    `
    ${CheckboxWrapper} {
      margin-top: 3px;
    }
  `}
`;

export const Error = styled(BaseError)`
  padding: 0;
  margin: 8px 0 0;
`;
