import styled from 'styled-components';
import { ProfilePicture as BaseProfilePicture } from '../../ProfilePicture';

const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
};

export const TextCellContainer = styled.div<{
  $fontWeight?: 'regular' | 'medium' | 'bold';
}>`
  display: flex;
  align-items: center;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 18px;
  font-weight: ${({ $fontWeight }) => fontWeights[$fontWeight || 'regular']};
  letter-spacing: ${({ $fontWeight }) =>
    $fontWeight === 'bold' ? '0.075px' : '0.05px'};
  & div {
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const ProfilePicture = styled(BaseProfilePicture)`
  width: 24px;
  height: 24px;
  margin-right: 6px;

  & div {
    font-size: 9px;
  }
`;

export const PrefixContainer = styled.div`
  margin-right: 6px;
  display: flex;
`;
