import {
  useCallback,
  useEffect,
  useState,
  useId,
  useRef,
  useImperativeHandle,
  forwardRef,
  ChangeEvent,
  ForwardRefRenderFunction,
  ReactElement,
} from 'react';
import {
  Label,
  CheckboxLine,
  CheckIcon,
  Container,
  Input,
  CheckboxWrapper,
  Error,
  LabelContainer,
} from './Checkbox.styles';
import Tooltip, { TooltipProps } from '../Tooltip';
import { HelperTextProp } from '../Label/Label';

export interface CheckboxProps {
  checked?: boolean;
  defaultChecked?: boolean;
  intermediate?: boolean;
  label?: string | ReactElement;
  error?: string;
  disabled?: boolean;
  helperText?: HelperTextProp;
  className?: string;
  onChange?: (value: boolean, e: ChangeEvent<HTMLInputElement>) => void;
  tooltip?: TooltipProps;
}

type CheckboxRef = HTMLInputElement | null;

const Checkbox: ForwardRefRenderFunction<CheckboxRef, CheckboxProps> = (
  props,
  ref,
) => {
  const {
    checked,
    defaultChecked,
    intermediate,
    label,
    error,
    disabled,
    helperText,
    className,
    onChange,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const [selected, setSelected] = useState<boolean>(!!defaultChecked);
  const id = useId();

  useImperativeHandle<CheckboxRef, CheckboxRef>(ref, () => inputRef.current);

  useEffect(() => {
    if (checked !== undefined) {
      setSelected(checked);
    }
  }, [checked]);

  useEffect(() => {
    if (intermediate !== undefined && inputRef?.current) {
      inputRef.current.indeterminate = intermediate;
    }
  }, [inputRef, intermediate, selected]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      if (onChange) {
        onChange(checked, e);
      } else {
        setSelected(checked);
      }
    },
    [onChange],
  );

  return (
    <>
      <Container
        onClick={(e) => {
          // prevents clicks from propagating to the parent container this is useful for the
          // scenario where the checkbox is inside a clickable container like an accordion
          e.stopPropagation();
        }}
        $disabled={disabled}
        $withLabel={Boolean(label)}
        data-testid="checkbox-wrapper"
        className={className}
      >
        <CheckboxWrapper>
          <Input
            data-testid="checkbox"
            ref={inputRef}
            id={id}
            checked={selected}
            onChange={handleChange}
            type="checkbox"
            disabled={disabled}
          />
          {selected && !intermediate && <CheckIcon data-testid="check-icon" />}
          {selected && intermediate && (
            <CheckboxLine data-testid="checkbox-line" />
          )}
        </CheckboxWrapper>
        {label && (
          <LabelContainer className="Wreno_Checkbox__label-container">
            <Label htmlFor={id} helperText={helperText}>
              {label}
            </Label>
            {props.tooltip && <Tooltip iconSize={14} {...props.tooltip} />}
          </LabelContainer>
        )}
      </Container>
      {error && <Error inline errorMessage={error} />}
    </>
  );
};

export default forwardRef<CheckboxRef, CheckboxProps>(Checkbox);
