import type { ButtonProps } from '../../Button';

import { Align, Button, ButtonWrapper } from './ButtonCell.styles';

export interface ButtonCellProps extends ButtonProps {
  align?: Align;
}

function ButtonCell(props: ButtonCellProps) {
  const { align, children, variant = 'secondary' } = props;

  return (
    <ButtonWrapper $align={align ?? 'right'}>
      <Button variant={variant} {...props}>
        {children}
      </Button>
    </ButtonWrapper>
  );
}

export default ButtonCell;
