import styled, { css } from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: auto;
`;

export const Tr = styled.tr``;

export const Td = styled.td<{
  $rowHeight?: number;
  $autoHeight?: boolean;
  $autoMinRowHeight?: number;
  $hasOnClick?: boolean;
}>`
  margin: 0;
  padding: 0 15px;
  font-family: ${(props) => props.theme.fontFamily.body};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  height: ${(props) =>
    props.$rowHeight
      ? `${props.$rowHeight}px`
      : props.$autoHeight
        ? 'auto'
        : '57px'};
  position: relative;
  align-items: center;
  display: flex;
  gap: 8px;

  ${({ $autoMinRowHeight }) =>
    $autoMinRowHeight &&
    `
    min-height: ${$autoMinRowHeight}px;
  `}

  ${({ $hasOnClick }) =>
    $hasOnClick &&
    css`
      cursor: pointer;
      transition: background 225ms ease-in;
      transition-property: background, border;
      position: relative;
      z-index: 1;

      & svg {
        width: 8px;
        opacity: 0;
        transition: opacity 225ms ease-in;
        color: ${({ theme }) => theme.colors.gray500};
      }

      &:hover {
        &:before {
          content: '';
          height: 80%;
          width: 100%;
          position: absolute;
          inset: 0;
          z-index: -1;
          top: 10%;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 8px;
        }

        & svg {
          opacity: 1;
        }
      }
    `}
`;

export const TableBody = styled.tbody<{
  $hideBodyBorder?: boolean;
  $tablePadding?: number;
}>`
  ${({ $tablePadding }) =>
    $tablePadding &&
    `
    ${Tr} {
      padding: 0 ${$tablePadding}px;
    }
  `}

  ${Td} {
    border-bottom: ${({ $hideBodyBorder }) =>
      $hideBodyBorder ? 'none' : '1px solid #e1e1e1'};
  }
  ${Tr}:hover {
    transition: background 175ms ease-out;
    background: ${({ theme }) => theme.colors.gray100};
  }
`;

export const TableFooter = styled.tfoot<{
  $tablePadding?: number;
  $hideBodyBorder?: boolean;
}>`
  ${Td} {
    border-bottom: ${({ $hideBodyBorder }) =>
      $hideBodyBorder ? 'none' : '1px solid #e1e1e1'};
  }

  ${({ $tablePadding }) =>
    $tablePadding &&
    `
    ${Tr} {
      padding: 0 ${$tablePadding}px;
    }
  `}
`;

export const TableHead = styled.thead<{
  $tablePadding?: number;
  $isSecondaryTheme?: boolean;
}>`
  ${Td} {
    word-wrap: nowrap;
    white-space: nowrap;
  }

  ${({ $isSecondaryTheme, theme }) =>
    $isSecondaryTheme &&
    `
    ${Tr} {
      background: ${theme.colors.gray100};
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  `}

  ${({ $tablePadding }) =>
    $tablePadding &&
    `
    ${Tr} {
      padding: 0 ${$tablePadding}px;
    }
  `}
`;

export const TableWrapper = styled.table<{
  $isSecondaryTheme?: boolean;
  $tablePadding?: number;
}>`
  width: 100%;
  overflow: hidden;
  border-spacing: 0;

  ${({ $isSecondaryTheme, theme }) =>
    $isSecondaryTheme &&
    `
    background: ${theme.colors.gray50};
    border-radius: 10px;
  `}

  ${({ $tablePadding }) =>
    $tablePadding &&
    `
    padding-bottom: ${$tablePadding}px;
  `}

  ${TableHead} {
    overflow-y: auto;
    overflow-x: hidden;
  }
  ${TableBody} {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
