import { PropsWithChildren } from 'react';
import { TableHeaderProps as ReactTableHeaderProps } from 'react-table';

import { Th, SortIconContainer } from './TableHeader.styles';
import ArrowUpIcon from '../../icons/ArrowUpIcon';
import { TableVariant } from '../Table';

export interface TableHeaderProps extends ReactTableHeaderProps {
  variant: TableVariant;
  isSorted: boolean;
  rowHeight?: number;
  isSortedDesc?: boolean;
}

function TableHeader(props: PropsWithChildren<TableHeaderProps>) {
  const { children, variant, isSorted, isSortedDesc, rowHeight, ...rest } =
    props;

  return (
    <Th $variant={variant} $rowHeight={rowHeight} {...rest}>
      {children}

      {isSorted && (
        <SortIconContainer $flip={isSortedDesc}>
          <ArrowUpIcon height={12} width={12} />
        </SortIconContainer>
      )}
    </Th>
  );
}

TableHeader.defaultProps = {
  variant: 'primary' as TableVariant,
};

export default TableHeader;
