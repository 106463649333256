import styled from 'styled-components';
import BaseButton from '../../Button';

const alignMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

export type Align = keyof typeof alignMap;

export const ButtonWrapper = styled.div<{ $align: Align }>`
  display: flex;
  justify-content: ${({ $align }) => alignMap[$align]};
  width: 100%;
`;

export const Button = styled(BaseButton)`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  padding: 6px 20px;
`;
